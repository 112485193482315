@font-face {
  font-family: 'giorgiosans';
  font-weight: 400;
  src: url('/fonts/giorgiosans-regular.otf') format('opentype');
}

@font-face {
  font-family: 'giorgiosans';
  font-weight: 300;
  src: url('/fonts/giorgiosans-light.otf') format('opentype');
}

@font-face {
  font-family: 'giorgiosans';
  font-weight: 200;
  src: url('/fonts/giorgiosans-extralight.otf') format('opentype');
}

@font-face {
  font-family: 'giorgiosans';
  font-weight: 100;
  src: url('/fonts/giorgiosans-thin.otf') format('opentype');
}

@font-face {
  font-family: 'unitroundedpro';
  font-weight: 300;
  src: url('/fonts/unitroundedpro-light.otf') format('opentype');
}
