:root {
  --c-yellow: #f39000;
  --c-transparent: transparent;
  --c-red: #d73e42;
  --c-black: #000;
  --c-white: #fff;
  --c-black-transparent: rgba(0, 0, 0, 0.4);
  --c-question-number: #db696b;
  --slider-primary: #d63e41;
  --slider-secondary: var(--c-white);
}
