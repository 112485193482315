@import './base/objects/fonts.css';
@import './base/vars/variables.css';
@import './base/vars/colors.css';
@import './base/vars/fonts.css';
@import 'normalize.css';

.js-focus-visible :focus:not([data-focus-visible-added]) {
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

/* *::-webkit-scrollbar {
  width: 0 !important;
} */

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'giorgiosans', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-top: 0;
}

h1,
h2 {
  width: 100%;
  height: 68px;
  max-width: 550px;
  margin: 0 auto 16px;
  font-size: 36px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 34px;
  letter-spacing: 1.5px;

  @media (--mobile) {
    height: 108px;
    font-size: 45px;
    line-height: 40px;
  }

  @media (--desktop) {
    font-size: 60px;
    line-height: 54px;
    letter-spacing: 2.5px;
  }
}

p {
  margin-top: 15px;
  font-family: var(--secondary-font);
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  line-height: 22px;
  letter-spacing: 0;

  @media (--mobile) {
    font-size: 17px;
    line-height: 24px;
  }
}

small {
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

button {
  padding: 0;
  border: 0;
  background: none;

  /* &:focus {
    outline: 0;
  } */

  &:hover {
    cursor: pointer;
  }
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-v-center {
  align-items: center;
}

.flex-h-center {
  justify-content: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-grow {
  flex: 1;
}

.center-inner {
  display: flex;
  min-height: 100%;
  padding: 85px 0 25px;
  flex-direction: column;

  @media (--desktop) {
    min-height: 766px;
    padding: 100px 0;
  }
}

.container {
  width: 100%;
  max-width: 1000px;
  padding: 0 15px;
  margin: 0 auto;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

.button {
  display: block;
  width: 100%;
  height: 55px;
  max-width: 330px;
  font-family: var(--secondary-font);
  font-size: 16px;
  text-align: center;
  line-height: 55px;
  transition: background 0.15s linear;

  @media (hover: hover) {
    &:hover {
      background: #a52b2e;
    }
  }

  @media (--desktop) {
    font-size: 17px;
  }
}

#root .button-primary {
  color: var(--c-white);
  background: var(--c-red);
}

#tealium-cookies .button-primary {
  background: var(--c-red);
}

.button-secondary {
  border: 2px solid var(--c-red);
  color: #fff;
  color: var(--c-white);
}

.yellow-font {
  color: var(--c-yellow);
}

.red-font {
  color: var(--c-red);
}

.center {
  margin: 0 auto;
}

.sub-copy {
  max-width: 590px;
  margin: 40px auto 0;
  color: #fff;
  font-family: var(--secondary-font);
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0;

  & br {
    display: none;
  }

  & p {
    margin: 0 0 15px;
    font-size: inherit;
    line-height: inherit;

    & br {
      display: inherit;
    }
  }

  & small {
    display: block;
    font-size: 13px;
    line-height: 21px;
  }

  @media (--desktop) {
    margin: 50px auto 0;
    font-size: 18px;
    line-height: 25px;
  }
}

.main-cta {
  margin-top: 25px;
}

.img-container {
  display: inline-block;
  margin: 0 auto;
}

scottishwidows-faceaging {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media (--large-desktop) {
    width: calc(100% - 400px);
    left: 400px;
  }
}

.error {
  margin-top: 15px;
  font-size: 20px;
}

/**
 * A third-party cookie-consent dialog is relatively positioned below our site.
 * This fixes that.
 */
.ui-widget-overlay {
  position: fixed !important;
}

/**
 * Change some colours on the third party cookie-consent dialog.
 */
.cookies .cookies-head {
  background-color: #2d2d2d;
}

.cookies .tealium_confirm,
.cookies a.tealium_more {
  background-color: var(--c-red);
}
